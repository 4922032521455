/* ##remfixer: 1080 */

.Comment {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #f2f2f2;
}

.Comment .NavBar {
  margin-bottom: 0;
}

.content {
  width: 100%;
}

.content .title {
  width: 100%;
  height: 2.731481rem;
  background: url("../assets/images/about_title.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.259259rem;
}

.content .text {
  padding: 0 0.611111rem;
  margin-bottom: 0.37037rem;
  float: left;
}

.content .text_title {
  width: 100%;
  text-align: center;
  font-size: 0.555556rem;
  color: rgba(11, 11, 11, 0.9);
  line-height: 0.666667rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.content .text_txt {
  line-height: 0.694444rem;
  font-size: 0.444444rem;
  color: rgba(51, 51, 51, 0.8);
  letter-spacing: 0;
  margin-bottom: 0.75rem;
}